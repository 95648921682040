import React, { useEffect, useState, useRef } from "react"
import { Link, graphql } from "gatsby";
import queryString from "query-string"
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CInputBtnList, CInputBtnInit, CRecruitMedia
} from "../../../components/_index"
import { recruitArea } from "../../../utils/recruit"

// markup
const SubPage = ({ data, location }: { data: any, location: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const { place } = queryString.parse(location.search)
  const iniplacelist = (place) ? (Array.isArray(place)) ? place : [place] : recruitArea.map((item: any) => item.label)
  const { checked, check, allCheck, loading } = CInputBtnInit(
    recruitArea.map((item: any) => item.label),
    iniplacelist
  )

  const inputData = recruitArea.map((item: any) => {
    return {
      value: item.label,
      label: item.label,
      onChange: check
    }
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    const replacequery = (checked.length != recruitArea.length && checked.length > 0) ? '?place=' + checked.join('&place=') : url.pathname
    history.replaceState({}, '', replacequery)
  })


  const filterData: Array<any> = data.allMicrocmsRecruitHotelstop.edges.filter((item: any) => {
    if (checked.length < 1) {
      return true
    } else {
      return item.node.location.some((el: string) => checked.includes(el))
    }
  })

  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            ja: <>パート・アルバイト採用<br className="u_sp" />募集要項</>
          },
          img: {
            src: '/assets/images/recruit/parttime/kv.png'
          },
          imgSp: {
            src: '/assets/images/recruit/parttime/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: '採用情報',
            path: '/recruit/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <div className="l_sect03 u_bgGray">
        <LWrap>
          <p className="c_sectLead">現在、募集を行っているホテルの検索ページです。<br />ご希望の地域を選択してください。</p>
        </LWrap>
      </div>
      <section className="l_sect04">
        <LWrap>
          <h2 className="c_headingLv3 u_tac">勤務地</h2>
          <CInputBtnList checkArray={checked} data={inputData} name="place" type="checkbox" all={{
            onChange: allCheck
          }} />
        </LWrap>
      </section>
      <div className="l_sect03 u_bgGray">
        <LWrap>
          {filterData.length ? (
            <CRecruitMedia data={filterData} />
          ) : (
            <p className="u_tac">現在掲載中の求人はありません。</p>
          )}
        </LWrap>
      </div>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
    allMicrocmsRecruitHotelstop(sort: {fields: sortIndex, order: ASC},filter: {employment: {eq: "パート・アルバイト"},recruitHotelstopId: {ne: "dummyrecruit"}}) {
      edges {
        node {
          description
          employment
          eyecatch {
            url
            height
            width
          }
          hotel {
            id
            hotelname
          }
          location
          publishedAt
          recruitHotelstopId
          title
        }
      }
    }
  }
`